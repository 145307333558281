import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();

    // Перевірка, чи збігаються паролі
    if (password !== confirmPassword) {
      setError("Password doesn't match");
      return;
    }
    // Відправка даних на сервер
    axios.post(process.env.REACT_APP_API_URL+'register', { email, password, username })
      .then(response => {
        // Після успішної реєстрації можна зберегти токен і перенаправити користувача
        localStorage.setItem('token', response.data.access_token);
        navigate('/');
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setError(error.response.data.message); 
        } else {
          console.error('Registration failed:', error);
          setError('Smth gone wrong');
        }
        setTimeout(() => {
          setError('');
        }, 3000);
      })
      .finally(() => {
      })
  };

  return (
    <div className="login-container">
      <div className="login-box registration">
        <h2>Registration</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label>Username</label>
            <input 
              maxLength="32"
              type="username" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required
            />
          </div>
          <div className="input-group">
            <label>Confirm Password</label>
            <input 
              type="password" 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              required
            />
          </div>
          <p className={`error-text  ${error ? '' : 'hiding-element'}`}>{error ? error : ''}</p>
          <button type="submit" className="login-button">Register</button>
        </form>
        <p className="register-text">
          Already registered? <span onClick={() => navigate('/login')}>Login</span>
        </p>
      </div>
    </div>
  );
};

export default Register;
