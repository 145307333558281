import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
export const GLTF_LOADER = new GLTFLoader();

export const STATUS_COLORS = {
    0: { firstColor: "#fcda00", secondColor: "#fcf400" }, // Може бути виконана
    1: { firstColor: "#00ff99", secondColor: "#00ffff" }, // Виконується
    2: { firstColor: "#ff0000", secondColor: "#ffa400" }, // Заблокована
    3: { firstColor: "#a200ff", secondColor: "#00ddff" }, // Виконана
    // 3: { firstColor: "#fff", secondColor: "#ff0000" }, // Виконана
};

// Функція для створення текстур
// const createTextures = () => {
//     const textures = {}; // Словник для збереження текстур
// 
//     Object.entries(STATUS_COLORS).forEach(([status, { firstColor, secondColor }]) => {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
// 
//         canvas.width = 512; // Ширина текстури
//         canvas.height = 512; // Висота текстури
// 
//         // Градієнт для текстури
//         const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
//         gradient.addColorStop(0.4, firstColor);
//         gradient.addColorStop(1, secondColor);
// 
//         // Заливка градієнтом
//         ctx.fillStyle = gradient;
//         ctx.fillRect(0, 0, canvas.width, canvas.height);
// 
//         // Додаємо текстуру до словника
//         textures[status] = new THREE.CanvasTexture(canvas);
//     });
// 
//     return textures; // Повертаємо словник текстур
// };
// 
// // Створюємо текстури для кожного статусу
// export const statusTextures = createTextures();

export const STATUS_OPTIONS = {
        0: "Available",
        1: "In Progress",
        2: "Blocked",
        3: "Completed",
};


export const DATE_OPTIONS = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };

export const IS_MOBILE = window.innerWidth < 768;
  
export const LINK_STATUS_COLORS = {
    0: "#cc9900", 
    1: "#009933", 
    2: "#ff6600", 
    3: "#6633ff", 
    null: '#808080',
};
  
export const NODE_STATUS_COLORS = {
    0: "#996600fe", 
    1: "#009933fe", 
    2: "#991911fe", 
    3: "#4444bbfe", 
    null: '#808080',
};
  
export const FAR_STATUS_SECONDARY_COLOR = {
    0: "#968300f0", 
    1: "#00568ff0", 
    2: "#913a00f0", 
    3: "#008f87f0", 
    null: '#808080',
};

export const TYPE_TEXT = {
    0: "Dream",
    1: "Global Goal",
    2: "Process",
    3: "Task",
    4: "Skill",
    5: "Tool",
    6: "Technology",
    7: "Resource",
    8: "Resource Source",
    null: "Undefined",
}

export const NODE_PROPS_TO_UPDATE = [
    "asignee",
    "attachments",
    "description",
    "due_date",
    "last_edited_time",
    "priority",
    "status",
    "tags",
    "title",
    "type",
    "volume",
];

export const LINK_PROPS_TO_UPDATE = ["type"];

export const BLOOM_SCENE = 1;

export const LINK_LENGTH = 80;

export const USE_AUDIO = false;
export const SHOW_STARS = true;

export const TITLE_MAX_CHARS = 60;

export const CARD_DIMENTIONS = { width: 10, height: 3 }

export const UNACTIVE_THRESHOLD = 300000;