import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            color: '#fff',
            backgroundColor: '#333',  // Фон сторінки
        }}>
            <h1 style={{ fontSize: '4rem', marginBottom: '1rem' }}>404</h1>
            <p style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>Oops! Page not found.</p>
            <Link to="/" style={{
                fontSize: '1.2rem',
                textDecoration: 'none',
                color: '#4CAF50',
                border: '1px solid #4CAF50',
                padding: '10px 20px',
                borderRadius: '5px',
            }}>Go back to Home</Link>
        </div>
    );
};

export default NotFound;
