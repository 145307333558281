import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Login from './webpages/Login';
import Registration from './webpages/Registration';
import Home from './webpages/Home';
import Sample from './webpages/Sample';
import NotFound from './webpages/NotFound';
import { isAuthenticated } from './utils/Helpers';
import { useSelector } from 'react-redux';

const RestrictedRoute = ({ children }) => {
    return isAuthenticated() ? <Navigate to="/" /> : children;
};

// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

function App() {
    const location = useLocation(); 
    //const navigate = useNavigate();
    const authStatus = useSelector((state) => state.auth?.isAuthenticated);

    // useEffect(() => {
    //     if (!authStatus) {
    //         navigate('/login');
    //     }
    // 
    // }, [authStatus, navigate])
    // Це зайве, бо вже є PrivateRoute, який захищає маршрути
    
    return (
        <TransitionGroup className='app'>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300} // Time of the animation in milliseconds
            >
                <Routes>
                    {/* Restricted Routes (for logged-out users) */}
                    <Route
                        path="/login"
                        element={
                            <RestrictedRoute>
                                <Login />
                            </RestrictedRoute>
                        }
                    />
                    <Route
                        path="/registration"
                        element={
                            <RestrictedRoute>
                                <Registration />
                            </RestrictedRoute>
                        }
                    />

                    {/* Private Routes (for logged-in users) */}
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/sample"
                        element={
                            <Sample />
                        }
                    />

                    {/* Fallback route for not found pages */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default App;
