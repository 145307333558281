import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InitialNodeLayout from '../components/layouts/InitialNodeLayout.js';
import NeuroSpace from '../components/layouts/NeuroSpace.js';
import Loader from '../components/layouts/Loader.js';
import { turnOffLoading, turnOnLoading } from '../store/graphSlice';

// const nodes = [
//   { id: '1', title: 'Card 1', status: 0, x: -10, y: 0, z: 0 },
//   { id: '2', title: 'Card 2', status: 1, x: 10, y: 0, z: 0 },
//   { id: '3', title: 'Card 3', status: 2, x: 0, y: 10, z: 0 },
//   { id: '4', title: 'Card 4', status: 3, x: 0, y: -10, z: 0 },
//   { id: '5', title: 'Card 5', status: 0, x: 0, y: 0, z: 10 }
// ];

// const links = [
//   { source: '1', target: '2', type: 0 },
//   { source: '1', target: '3', type: 1 },
//   { source: '2', target: '4', type: 0 },
//   { source: '3', target: '5', type: 1 },
//   { source: '4', target: '5', type: 0 }
// ];


const Home = () => {
  const { graphData, loading } = useSelector((state) => state.graph);
  const [isFirstNode, setIsFirstNode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    setIsFirstNode(graphData.nodes.length === 0);
  }, [graphData, navigate]);
  
  return (
        !isFirstNode ? (
          <NeuroSpace />
        ) : (
          <InitialNodeLayout setIsFirstNode={setIsFirstNode} />
        )
      )
};

export default Home;
