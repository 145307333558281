import React, { useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import { updateCoordinatesRequest } from '../store/graphSlice';
import { createGradientTexture } from 'utils/Helpers';
import { turnOffLoading, turnOnLoading } from '../store/graphSlice';
import { Text } from 'troika-three-text';
import { FlyControls } from 'three/examples/jsm/controls/FlyControls';
import { gsap } from 'gsap';

import { 
  LINK_STATUS_COLORS,
  NODE_STATUS_COLORS,
  SHOW_STARS,
  USE_AUDIO,
  STATUS_COLORS,
  CARD_DIMENTIONS,
  TITLE_MAX_CHARS,
  UNACTIVE_THRESHOLD,
  GLTF_LOADER,
} from 'utils/Constants'


const loadShader = async (path) => {
  const response = await fetch(path);
  return await response.text();
};
const corpusVert = await loadShader('/shaders/corpus.vert');
const corpusFrag = await loadShader('/shaders/corpus.frag');
const sNoiseVert = await loadShader('/shaders/sNoise.vert');
const sNoiseFrag = await loadShader('/shaders/sNoise.frag');
const starsVert = await loadShader('/shaders/stars.vert');
const starsFrag = await loadShader('/shaders/stars.frag');


const Graph3D = ({
  data,
  needSimulation,
  fgRef,
  focusOnNode,
  focusNodeId,
  getConnected,
  lineDeleteButtonRef,
  lineTypeButtonRef,
  lineAddNodeButtonRef,
  lineReverseButtonRef,
}) => {
  const graphData = useRef(data);
  // const graphData = {nodes: [{id:'1', title:'Task blocked title + кирилиця', weight: 100, status:2},{id:'2', title:'Назва кирилична', weight: 10, status:0},{id:'3', title:'Task done title', weight: 100, status:3}], links: [{source: '2', target:'1', type:0},{source: '3', target:'1', type:0}]};
  
  // const glassMaterial = useRef(null);
  const dummy = new THREE.Object3D();

  // graphData.links.forEach(link => {
  //   link.source = graphData.nodes.find(node => node.id === link.source);
  //   link.target = graphData.nodes.find(node => node.id === link.target);
  // });

  const dispatch = useDispatch();
  const firstTime = useRef(true);
  
  const hoveredNode = useRef();
  const focusedNodeId = useRef(null);
  const newLinkStart = useRef(null);
  const newLinkEnd = useRef(null);

  const attributes = useRef({
    times: new Float32Array(graphData.current.nodes.length),
    rs: new Float32Array(graphData.current.nodes.length),
    firstColors: new Float32Array(graphData.current.nodes.length * 3),
    secondColors: new Float32Array(graphData.current.nodes.length * 3),
    intensities: new Float32Array(graphData.current.nodes.length),
    positions: new Float32Array(graphData.current.nodes.length * 3),
    scales: new Float32Array(graphData.current.nodes.length),
  });

  let tickCount = 0;
  
  useEffect(() => {
    if (!fgRef.current) return;
    graphData.current = data;

    const camera = fgRef.current.camera();
    const scene = fgRef.current.scene();
    const renderer = fgRef.current.renderer();
    const controls = fgRef.current.controls();
    if (!camera || !scene || !renderer || !controls) return;

    controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
    
    controls.keyPanSpeed = 70;
    controls.keys = ['KeyF', 'KeyG', 'KeyH'];
    controls.panSpeed = 0.06;
    controls.zoomToCursor = true;
    // controls.rotateSpeed = 0.4;

    controls.update();

    const maxPolarAngle = Math.PI - 0.1; // Максимальний нахил
    const minPolarAngle = 0.1;
    const spherical = new THREE.Spherical(); 

    let lastActive = Date.now();

    const flyControls = { speed: 10, direction : {x: 0, y: 0, z: 0} };
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW': flyControls.direction.z = 1; break;
        case 'KeyS': flyControls.direction.z = -1; break;
        case 'KeyA': flyControls.direction.x = 1; break;
        case 'KeyD': flyControls.direction.x = -1; break;
        case 'KeyQ': flyControls.direction.y = -1; break;
        case 'KeyE': flyControls.direction.y = 1; break;
        default: break;
    }};
    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW': case 'KeyS': flyControls.direction.z = 0; break;
        case 'KeyA': case 'KeyD': flyControls.direction.x = 0; break;
        case 'KeyQ': case 'KeyE': flyControls.direction.y = 0; break;
        default: break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    graphData.current.nodes.forEach((node, index) => {
      attributes.current.times[index] = Math.random() * 10.0;
      attributes.current.rs[index] = Math.log(node.weight + 10)**2;
      const firstColor = new THREE.Color(STATUS_COLORS[node.status].firstColor);
      const secondColor = new THREE.Color(STATUS_COLORS[node.status].secondColor);
    
      attributes.current.firstColors.set([firstColor.r, firstColor.g, firstColor.b], index * 3);
      attributes.current.secondColors.set([secondColor.r, secondColor.g, secondColor.b], index * 3);
  
      attributes.current.positions.set([node.x, node.y, node.z], index * 3);
      attributes.current.scales[index] = Math.log(node.weight + 10) ** 1.8;
  
      attributes.current.intensities[index] = 1.0;
    });

    const interactableObjects = [];

    const corpusDepth = 0.7;

    const titlesMeshes = graphData.current.nodes.map(node => {
          // Створюємо текстовий об'єкт
      const textMesh = new Text();
      textMesh.geometry.translate(0, 0, corpusDepth / 2 + 0.05);
      textMesh.depthOffset = -1;
      // textMesh.depthOffset = -2;
      textMesh.anchorX = -1.3;
      textMesh.anchorY = -1.3;
      textMesh.text = node.title.length > TITLE_MAX_CHARS ? node.title.slice(0, TITLE_MAX_CHARS-3) + '...' : node.title || "Untitled";
      textMesh.font = 'roboto_bold.ttf'; // URL до шрифту
      textMesh.fontSize = 0.5; // Розмір тексту
      textMesh.color = node.title ? 'white' : '#303030';
      textMesh.textAlign = 'left'; // Вирівнювання по лівому краю
      textMesh.maxWidth = 6; // Максимальна ширина тексту
      textMesh.lineHeight = 1.2; // Відстань між рядками
      textMesh.position.set(node.x, node.y, node.z-10);
      textMesh.sync(); // Оновлюємо текст
      return textMesh;
    });

    const snoiseMesh = new THREE.InstancedMesh(
      new THREE.SphereGeometry(1, 32, 32),
      new THREE.ShaderMaterial({
        vertexShader: sNoiseVert,
        fragmentShader: sNoiseFrag,
        vertexColors: true,
        transparent: false,
        wireframe: true,
        side: THREE.DoubleSide,
      }),
      graphData.current.nodes.length
    );
    snoiseMesh.frustumCulled = false;
    snoiseMesh.geometry.setAttribute("u_time", new THREE.InstancedBufferAttribute(attributes.current.times, 1));
    snoiseMesh.geometry.setAttribute("u_r", new THREE.InstancedBufferAttribute(attributes.current.rs, 1));
    snoiseMesh.geometry.setAttribute("firstColor",new THREE.InstancedBufferAttribute(attributes.current.firstColors, 3));
    snoiseMesh.geometry.setAttribute("secondColor",new THREE.InstancedBufferAttribute(attributes.current.secondColors, 3));


    const borderWidth = 0.1;
    const holeRadius = 1;
    let x = -holeRadius * 1.5;
    let y = -holeRadius * 1.5;
    let width = CARD_DIMENTIONS.width;
    let height = CARD_DIMENTIONS.height;
    let radius = 0.7;

    const corpusShape = new THREE.Shape();
    
    corpusShape.moveTo(x - borderWidth, y + radius);
    corpusShape.lineTo(x - borderWidth, y + height - radius - borderWidth);
    corpusShape.quadraticCurveTo(
      x - borderWidth,
      y + height + borderWidth,
      x + radius,
      y + height + borderWidth
    );
    corpusShape.lineTo(x + width - radius + borderWidth, y + height + borderWidth);
    corpusShape.quadraticCurveTo(
      x + width + borderWidth,
      y + height + borderWidth,
      x + width + borderWidth,
      y + height - radius - borderWidth
    );
    corpusShape.lineTo(x + width + borderWidth, y + radius - borderWidth);
    corpusShape.quadraticCurveTo(
      x + width + borderWidth,
      y - borderWidth,
      x + width - radius - borderWidth,
      y - borderWidth
    );
    corpusShape.lineTo(x + radius + borderWidth, y - borderWidth);
    corpusShape.quadraticCurveTo(
      x - borderWidth,
      y - borderWidth,
      x - borderWidth,
      y + radius - borderWidth
    );

    const hole = new THREE.Path();
    hole.absarc(0, 0, holeRadius, 0, Math.PI * 2, false);
    const holePoints = hole.getSpacedPoints(64);
    corpusShape.holes.push(new THREE.Path(holePoints));

    const corpusGeometry = new THREE.ExtrudeGeometry(corpusShape, { depth: corpusDepth, bevelEnabled: false,});
    corpusGeometry.translate(0, 0, -corpusDepth / 2);

    const corpusMaterial = new THREE.ShaderMaterial({
      uniforms: {
        baseColor: { value: new THREE.Color(0x909090) },
        embossColor: { value: new THREE.Color(0xffffff) },
        embossStrength: { value: 5.0 },
        lightPosition: { value: new THREE.Vector3(10, 10, 10) },
        ambientColor: { value: new THREE.Color(0.3, 0.3, 0.3) },
        diffuseColor: { value: new THREE.Color(0.1, 0.1, 0.1) },
        specularColor: { value: new THREE.Color(0.2, 0.2, 0.2) },
        shininess: { value: 32.0 },
      },
      vertexShader: corpusVert,
      fragmentShader: corpusFrag,
      transparent: false,
    });

    const corpusMesh = new THREE.InstancedMesh(
      corpusGeometry,
      corpusMaterial,
      graphData.current.nodes.length
    );
    corpusMesh.frustumCulled = false;
    corpusMesh.geometry.setAttribute("u_time", new THREE.InstancedBufferAttribute(attributes.current.times, 1));
    corpusMesh.geometry.setAttribute("u_r", new THREE.InstancedBufferAttribute(attributes.current.rs, 1));
    corpusMesh.geometry.setAttribute("firstColor",new THREE.InstancedBufferAttribute(attributes.current.firstColors, 3));
    corpusMesh.geometry.setAttribute("secondColor",new THREE.InstancedBufferAttribute(attributes.current.secondColors, 3));
    corpusMesh.geometry.setAttribute("intensity",new THREE.InstancedBufferAttribute(attributes.current.intensities, 1));
    corpusMesh.geometry.attributes.u_time.needsUpdate = true;
    corpusMesh.geometry.attributes.u_r.needsUpdate = true;
    corpusMesh.geometry.attributes.firstColor.needsUpdate = true;
    corpusMesh.geometry.attributes.secondColor.needsUpdate = true;
    corpusMesh.geometry.attributes.intensity.needsUpdate = true;
    
    corpusMesh.rcName = 'corpus';
    corpusMesh.instanceMatrix.needsUpdate = true;
    interactableObjects.push(corpusMesh);

    
    scene.add(snoiseMesh);
    scene.add(corpusMesh);
    titlesMeshes.forEach(mesh => {
      scene.add(mesh);
    });

    // const verstak = new THREE.Mesh(
    //   corpusGeometry,
    //   new THREE.MeshStandardMaterial({
    //     color: '#303030',
    //     transparent: false,
    //   })
    // );
    // verstak.scale.set(10, 10, 10)

    // interactableObjects.push(verstak);
    // scene.add(verstak);

    // const sphere_verstak = new THREE.Mesh(new THREE.SphereGeometry(1), new THREE.MeshBasicMaterial({ color: 0xff0000 }));
    // scene.add(sphere_verstak);

    if (localStorage.getItem('gift') === 'true') GLTF_LOADER.load('models/gift/scene.gltf', gltf => {
      const scale = Math.log(graphData.current.nodes.length + 10) / 2;
      gltf.scene.scale.set(scale, scale, scale);

      const leftLight = new THREE.DirectionalLight(0xff00ff, 1.5);
      leftLight.position.set(-scale*0.2, -scale/100, -scale*0.2);
      leftLight.target.position.set(0, 0, 0);
      leftLight.layers.set(1);
      scene.add(leftLight);
      scene.add(leftLight.target);

      const rightLight = new THREE.DirectionalLight(0x00ffff, 1.5);
      rightLight.position.set(scale*0.2, -scale/100, -scale*0.2);
      rightLight.target.position.set(0, 0, 0);
      rightLight.layers.set(1);
      scene.add(rightLight);
      scene.add(rightLight.target);
      
      gltf.scene.position.set(0, -scale*50, 0);
      gltf.scene.layers.set(1);
      gltf.scene.layers.disable(0);
      gltf.scene.traverse(child => {
        child.layers.set(1);
        if (child.isLight) gltf.scene.remove(child);
      });

      scene.children.forEach(child => {
        child.layers.set(0);
        if(child) child.traverse(c => {
          c.layers.set(0);
        })
      });

      camera.layers.enable(1);

      scene.add(gltf.scene);

      const animate = () => {

        gltf.scene.rotation.y -= 0.001;
        requestAnimationFrame(animate);
      }
      animate();
    });


    const card_settings_gears = new THREE.Group();
    card_settings_gears.isReady = false

    GLTF_LOADER.load('models/card_settings/scene.gltf', gltf => {
      const hoverBrightnessMult = 5;
      card_settings_gears.transitionSpeed = 0.4;

      card_settings_gears.firstColor = new THREE.Color(STATUS_COLORS[graphData.current.nodes[0].status].firstColor.slice(0, 7)).multiplyScalar(0.8);
      card_settings_gears.firstHoverColor = card_settings_gears.firstColor.clone().multiplyScalar(hoverBrightnessMult);
      card_settings_gears.firstMaterial = new THREE.MeshStandardMaterial({
        color: card_settings_gears.firstColor,
        metalness: 0.7,
        roughness: 0.3,
      });
      card_settings_gears.secondColor = new THREE.Color(STATUS_COLORS[graphData.current.nodes[0].status].firstColor.slice(0, 7)).multiplyScalar(0.8);
      card_settings_gears.secondHoverColor = card_settings_gears.secondColor.clone().multiplyScalar(hoverBrightnessMult);
      card_settings_gears.secondMaterial = new THREE.MeshStandardMaterial({
        color: card_settings_gears.secondColor,
        metalness: 0.7,
        roughness: 0.3,
      });
  
      let index = 0;
      gltf.scene.traverse((child) => {
        if(child.isMesh) {
          child.material.dispose();
          child.material = index === 0 ? card_settings_gears.firstMaterial :  card_settings_gears.secondMaterial;
          index++;
        }
      });
  
      card_settings_gears.xOffs = 7.3;
      card_settings_gears.yOffs = 2.4;
      gltf.scene.scale.set(0.4, 0.4, 0.4);
      gltf.scene.position.set(card_settings_gears.xOffs, 0, -corpusDepth/2);
      gltf.scene.rotation.x = Math.PI/3;
      gltf.scene.rotation.y = Math.PI/3;
  
      card_settings_gears.add(gltf.scene);

      // const interactivePlane = new THREE.Mesh(new THREE.PlaneGeometry(3, 1.8), new THREE.MeshBasicMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
      const interactiveSprite = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
      
      // interactivePlane.center.set(0.5, 0.5, 0);
      interactiveSprite.position.set(card_settings_gears.xOffs - 0.5, card_settings_gears.yOffs, 0);

      interactiveSprite.rcName = 'cset';
      interactableObjects.push(interactiveSprite);
      card_settings_gears.add(interactiveSprite);
  
      const mixer = new THREE.AnimationMixer(card_settings_gears);
  
      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play();
      });
  
      card_settings_gears.isHovered = false;
  
      card_settings_gears.animate = () => {
        mixer.update(card_settings_gears.isHovered ? -0.02 : 0.005);
      }

      card_settings_gears.onClick = () => {

      }
  
      card_settings_gears.onHover = (hovered) => {
        if (hovered !== card_settings_gears.isHovered) {
          card_settings_gears.isHovered = hovered;

          document.body.style.cursor = hovered ? 'pointer' : 'default';
        }
      }

      card_settings_gears.onCorpusHover = (instanceId) => {
        if (card_settings_gears.instanceId !== instanceId) {
          card_settings_gears.instanceId = instanceId;
          if (instanceId === null) return;
          gltf.scene.scale.set(0.4, 0.4, 0.4);
          interactiveSprite.scale.set(3, 1.8, 1);

          gltf.scene.position.y = 0;

          card_settings_gears.firstColor = new THREE.Color(STATUS_COLORS[graphData.current.nodes[instanceId].status].firstColor.slice(0, 7)).lerp(new THREE.Color(0x505050), 0.5);
          card_settings_gears.firstHoveredColor = card_settings_gears.firstColor.clone().multiplyScalar(hoverBrightnessMult);
          card_settings_gears.firstMaterial.color.set(card_settings_gears.firstColor);

          card_settings_gears.secondColor = new THREE.Color(STATUS_COLORS[graphData.current.nodes[instanceId].status].secondColor.slice(0, 7)).lerp(new THREE.Color(0x505050), 0.5);
          card_settings_gears.secondHoveredColor = card_settings_gears.secondColor.clone().multiplyScalar(hoverBrightnessMult);
          card_settings_gears.secondMaterial.color.set(card_settings_gears.secondColor);

        }
      }

      card_settings_gears.instanceId = null;

      scene.add(card_settings_gears);
      card_settings_gears.scale.set(0, 0, 0);

      card_settings_gears.isReady = true;
    });

    GLTF_LOADER.load('models/card_settings/trash_can/scene.gltf', gltf => {
      card_settings_gears.trash = gltf.scene;
      card_settings_gears.trash.material = new THREE.MeshStandardMaterial({color: 0xff3300, metalness: 0.7, roughness: 0.5, });

      // scene.add(gltf.scene);
      card_settings_gears.trash.isReady = true;
    })


    const link_settings = new THREE.Group();
    link_settings.isReady = false;

    GLTF_LOADER.load('models/link_settings/scene.gltf', gltf => {
      link_settings.material = new THREE.MeshStandardMaterial({
        color: new THREE.Color(LINK_STATUS_COLORS[graphData.current.nodes[0].status]),
        metalness: 0.7,
        roughness: 0.3,
        transparent: true,
        opacity: 0.5,
        alphaTest: 0.01,
      });

      gltf.scene.traverse(child => {
        if(child.isMesh) {
          child.material.dispose();
          child.material = link_settings.material;
        }
      });

      gltf.scene.scale.set(0.1, 0.1, 0.1);
      gltf.scene.rotation.y = Math.PI;
      gltf.scene.position.set(0, 0, -5);

      link_settings.gear = gltf.scene;
      link_settings.add(gltf.scene);

      link_settings.scale.set(0, 0, 0);
      scene.add(link_settings);
      link_settings.isReady = true;

      link_settings.rotationSpeed = 0.002;

      const interactiveSprite = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
      interactiveSprite.scale.set(10,10,1);
      interactiveSprite.rcName = 'lset';
      interactableObjects.push(interactiveSprite);

      link_settings.add(interactiveSprite);

      link_settings.onHover = (hovered) => {
        if (hovered !== link_settings.isHovered) {
          link_settings.isHovered = hovered;

          link_settings.rotationSpeed = hovered || link_settings.pinned ? -0.008 : 0.002;

          document.body.style.cursor = hovered ? 'pointer' : 'default';
        }
      }

      link_settings.onClick = () => {
        link_settings.pinned = !link_settings.pinned;

        if (link_settings.pinned ) gsap.to(link_settings.scissors.scale, { x: 100, y: 100, z: 100, duration: 0.5, delay: 1, ease: 'bounce.out', });
      }

      const animate = () => {
        gltf.scene.rotation.z += link_settings.rotationSpeed;

        requestAnimationFrame(animate);
      }

      animate();
    });

    GLTF_LOADER.load('models/link_settings/scissors/scene.gltf', gltf => {
      const mashes = [];
      gltf.scene.traverse(child => {
        if(child.isMesh) {
          mashes.push(child);
        }
      });

      const openAngle = Math.PI/6;
      const halfOpenAngle = Math.PI/12;

      mashes[0].rotation.z = -openAngle;
      mashes[1].rotation.z = openAngle;

      mashes[0].material.transparent = mashes[1].material.transparent = true;
      mashes[0].material.opacity = mashes[1].material.opacity = 0.2;

      gltf.scene.rotation.y = Math.PI / 2;
      gltf.scene.rotation.z = Math.PI / 2;

      gltf.scene.position.set(3, 0, -10);

      link_settings.add(gltf.scene);
      
      gltf.scene.scale.set(0, 0, 0);

      const interactiveSprite = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
      interactiveSprite.scale.set(0.1, 0.1, 1);
      interactiveSprite.rcName = 'scissors';
      interactableObjects.push(interactiveSprite);
      gltf.scene.add(interactiveSprite);

      gltf.scene.onHover = (hovered) => {
        if (hovered !== gltf.scene.isHovered) {
          gltf.scene.isHovered = hovered;

          gsap.to(mashes[0].rotation, { z: hovered ? -halfOpenAngle : -openAngle, duration: 0.3, ease: 'sine.inOut', });
          gsap.to(mashes[1].rotation, { z: hovered ? halfOpenAngle : openAngle, duration: 0.3, ease: 'sine.inOut', });

          gsap.to(mashes[0].material, { opacity: hovered ? 1 : 0.2, duration: 0.3, ease: 'sine.inOut' });
          gsap.to(mashes[1].material, { opacity: hovered ? 1 : 0.2, duration: 0.3, ease: 'sine.inOut' });

          document.body.style.cursor = hovered ? 'pointer' : 'default';
        }
      }

      link_settings.scissors = gltf.scene;
      link_settings.add(gltf.scene);

      link_settings.scissors.isReady = true;
    })

    const mouse = new THREE.Vector2();
    const raycaster = new THREE.Raycaster();

    const handleMouseMove = (e) => {
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);


      if (link_settings.isReady) {
        if (corpusMesh.isHovered) {
          link_settings.scale.set(0, 0, 0);
        } else {
          let minDistance = Infinity;
          let closestPoint = null;
          let scale = 0;

          graphData.current.links.forEach(link => {
            const sourcePos = link.source.__threeObj?.position;
            const targetPos = link.target.__threeObj?.position;
            if (!sourcePos
              || !targetPos
              || (link_settings.pinned && link_settings.link !== link)
            ) return;

            const closestPointOnLink = new THREE.Vector3();
            const distance = Math.sqrt(raycaster.ray.distanceSqToSegment(sourcePos, targetPos, null, closestPointOnLink));
            
            if (link_settings.pinned && distance > 100) {
              link_settings.pinned = false;
              link_settings.scissors.scale.set(0, 0, 0);
            }

            if (!link_settings.pinned && distance < minDistance && distance <= 70) {
              closestPoint = closestPointOnLink;

              const linkLength = sourcePos.distanceTo(targetPos);
              const distanceToSource = closestPoint.distanceTo(sourcePos);
              const relativePosition = distanceToSource / linkLength;

              if (relativePosition < 0.2) closestPoint.lerpVectors(sourcePos, targetPos, 0.2);
              else if (relativePosition > 0.8) closestPoint.lerpVectors(sourcePos, targetPos, 0.8);

              minDistance = distance;
              link_settings.link = link;
              scale = Math.log((link.source.weight || 4) + 1);
              link_settings.scale.set(scale, scale, scale);
              link_settings.material.opacity = link_settings.isHovered ? 1 : 0.4 - Math.max(0, (distance - 35)/35);
              link_settings.material.color.set(link_settings.link.type ? 0x777777 : new THREE.Color(LINK_STATUS_COLORS[link_settings.link.source.status]));
              link_settings.quaternion.copy(
                new THREE.Quaternion().setFromUnitVectors(
                new THREE.Vector3(0, 0, 1),
                new THREE.Vector3().subVectors(link_settings.link.target, link_settings.link.source).normalize(),
              ));
            } 
          });
          

          if (closestPoint) {
            if (!link_settings.pinned) {
              link_settings.position.set(closestPoint.x, closestPoint.y, closestPoint.z);
            }
          }
        }
      }

      if (!interactableObjects) return;

      const intersect = raycaster.intersectObjects(interactableObjects)[0];
      const rcName = intersect?.object?.rcName;

      if (link_settings.isReady && !corpusMesh.isHovered) link_settings.onHover(rcName === 'lset');
      if (link_settings.scissors?.isReady) link_settings.scissors.onHover(rcName === 'scissors');

      if (card_settings_gears.isReady) {
        if (rcName === 'corpus') {
          corpusMesh.isHovered = true;
          card_settings_gears.onHover(false);
          card_settings_gears.onCorpusHover(intersect.instanceId);
        }
        else if (rcName === 'cset') card_settings_gears.onHover(true);
        else {
          corpusMesh.isHovered = false;
          card_settings_gears.onCorpusHover(null);
          card_settings_gears.onHover(false);
        }
        
      }
    };
    document.addEventListener('mousemove', handleMouseMove);

    const handleMouseClick = () => {
      if (link_settings.isHovered) link_settings.onClick();
      else if (card_settings_gears.isHovered) card_settings_gears.onClick();
    }
    document.addEventListener('click', handleMouseClick);

    focusOnNode.current = (node) => {
      if (!node) return;
      const duration = 1500;
      
      const distance = 600; // Відстань до вузла
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
    
      const newCameraPos = {
        x: node.x * distRatio,
        y: node.y * distRatio,
        z: node.z * distRatio,
      };
  
      const initialDirection = new THREE.Vector3();
      camera.getWorldDirection(initialDirection);
  
    
      fgRef.current.cameraPosition(
        newCameraPos, // нова позиція камери
        { x: node.x, y: node.y - 50, z: node.z }, // точка, на яку дивиться камера
        duration // тривалість анімації в мс
      );
      setTimeout(() => {
        controls.update();
      }, duration+1);
    };
    
    // Початкове налаштування розміру
    const bgTexture = createGradientTexture(window.innerWidth, window.innerHeight);
    scene.background = bgTexture;

    fgRef.current.d3Force('link').distance(link => {
      return (
        Math.log((link.source?.weight || 5) + 1)*6
        + Math.log((link.target?.weight || 5) +1)*2
      )*8
    });

    fgRef.current.d3Force('charge').strength(Math.max(-graphData.current.nodes.length*2, -200));    

    const cameraQuaternion = new THREE.Quaternion();
    const instanceQuaternions = new Array(graphData.current.nodes.length).fill().map(() => new THREE.Quaternion());

    const unactiveUserAnimation = { rotationSpeed: 0};

    const animate = () => {
      const up = camera.up.clone();

      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);

      // const offset = camera.position.clone().sub(controls.target);
      // spherical.setFromVector3(offset);
// 
      // spherical.phi = Math.max(minPolarAngle, Math.min(maxPolarAngle, spherical.phi));
// 
      // offset.setFromSpherical(spherical);
      // camera.position.copy(controls.target).add(offset);

      const right = new THREE.Vector3();
      right.crossVectors(up, direction).normalize();

      // camera.up.copy(up);

      if (Date.now() - lastActive > UNACTIVE_THRESHOLD) {
        if (unactiveUserAnimation.needStart) {
          unactiveUserAnimation.basicSpeed = Math.random() < 0.5 ? -0.0005 : 0.0005;
          unactiveUserAnimation.needStart = false;
          unactiveUserAnimation.cameraPosition = camera.position.clone();
          unactiveUserAnimation.targetPosition = controls.target.clone();
          //fgRef.current.zoomToFit(5000);
        }
        unactiveUserAnimation.rotationSpeed = THREE.MathUtils.lerp(unactiveUserAnimation.rotationSpeed, unactiveUserAnimation.basicSpeed * camera.position.distanceTo(controls.target), 0.002);
        // spherical.phi = THREE.MathUtils.lerp(spherical.phi, Math.PI / 2, 0.0001);
        // offset.setFromSpherical(spherical);
        // camera.position.copy(controls.target).add(offset);
      } else {
        if (!unactiveUserAnimation.needStart) {
          unactiveUserAnimation.needStart = true;
          fgRef.current.cameraPosition(unactiveUserAnimation.cameraPosition, unactiveUserAnimation.targetPosition, 500);
        }
        unactiveUserAnimation.rotationSpeed = 0;
      }
      
      camera.position.addScaledVector(direction, flyControls.direction.z * flyControls.speed);
      camera.position.addScaledVector(right, flyControls.direction.x * flyControls.speed + unactiveUserAnimation.rotationSpeed);
      camera.position.addScaledVector(up, flyControls.direction.y * flyControls.speed + unactiveUserAnimation.rotationSpeed/10);
      
      controls.target.addScaledVector(direction, flyControls.direction.z * flyControls.speed);
      controls.target.addScaledVector(right, flyControls.direction.x * flyControls.speed);
      controls.target.addScaledVector(up, flyControls.direction.y * flyControls.speed);
      
      controls.update();


      camera.getWorldQuaternion(cameraQuaternion);
      

      graphData.current.nodes.forEach((node, index) => {
        dummy.position.set(
          attributes.current.positions[index * 3],
          attributes.current.positions[index * 3 + 1],
          attributes.current.positions[index * 3 + 2]
        );
  
        node.isFar = camera.position.distanceTo(dummy.position) > Math.max(1200, 200 * Math.sqrt(node.weight + 1));
        
        attributes.current.scales[index] = THREE.MathUtils.lerp(attributes.current.scales[index], node.isFar ? 0 : Math.log(node.weight + 10) ** 1.8, 0.2); // Плавна зміна масштабу
  
        dummy.scale.set(attributes.current.scales[index], attributes.current.scales[index], attributes.current.scales[index]);
  
        instanceQuaternions[index].slerp(cameraQuaternion, 0.05);
        
        const titleMesh = titlesMeshes[index];
        titleMesh.position.set(
          attributes.current.positions[index * 3],
          attributes.current.positions[index * 3 + 1],
          attributes.current.positions[index * 3 + 2]
        );
        titleMesh.quaternion.slerp(cameraQuaternion, 0.05); // Поворот тексту до камери
        //titleMesh.updateMatrix();
        titleMesh.scale.set(attributes.current.scales[index], attributes.current.scales[index], attributes.current.scales[index]);
        dummy.quaternion.copy(instanceQuaternions[index]);
  
        dummy.updateMatrix();
  
        const scale = Math.log(node.weight + 10) ** 1.8;
        const snoiseMatrix = new THREE.Matrix4();
        snoiseMatrix.compose(
          dummy.position,      // Позиція
          new THREE.Quaternion(),      // Без обертання
          new THREE.Vector3(scale, scale, scale) // Старий масштаб
        );


  
        if (card_settings_gears.instanceId === index) {
          if (card_settings_gears.isHovered) {
            card_settings_gears.firstMaterial.color.lerp(card_settings_gears.firstHoveredColor, card_settings_gears.transitionSpeed);
            card_settings_gears.secondMaterial.color.lerp(card_settings_gears.secondHoveredColor, card_settings_gears.transitionSpeed);
          } else {
            card_settings_gears.firstMaterial.color.lerp(card_settings_gears.firstColor, card_settings_gears.transitionSpeed);
            card_settings_gears.secondMaterial.color.lerp(card_settings_gears.secondColor, card_settings_gears.transitionSpeed);
          }
          card_settings_gears.children[0].position.y = THREE.MathUtils.lerp(card_settings_gears.children[0].position.y, card_settings_gears.yOffs, card_settings_gears.transitionSpeed);
          
          card_settings_gears.position.copy(dummy.position);
          card_settings_gears.quaternion.copy(dummy.quaternion);
          card_settings_gears.scale.copy(dummy.scale);

          card_settings_gears.animate();
        }

        snoiseMesh.setMatrixAt(index, snoiseMatrix);
        corpusMesh.setMatrixAt(index, dummy.matrix);
        attributes.current.times[index] += 0.02;
      });

      if (card_settings_gears.instanceId === null) {
        card_settings_gears.children[0].position.y = THREE.MathUtils.lerp(card_settings_gears.children[0].position.y, 0, card_settings_gears.transitionSpeed);
        if (card_settings_gears.children[0].position.y < 0.01){
          card_settings_gears.children[0].scale.set(0, 0, 0);
          card_settings_gears.children[1].scale.set(0, 0, 0);
        }
      }

      snoiseMesh.instanceMatrix.needsUpdate = true;
      corpusMesh.instanceMatrix.needsUpdate = true;
  
      snoiseMesh.geometry.attributes.u_time.needsUpdate = true;
      corpusMesh.geometry.attributes.u_time.needsUpdate = true;
      requestAnimationFrame(animate);
    };
    animate();

    if (SHOW_STARS) {
      const starGeometry = new THREE.BufferGeometry();
      
      const textureLoader = new TextureLoader();
      const blurredCircleTexture = textureLoader.load('https://threejs.org/examples/textures/sprites/circle.png');

      const starsVertices = [];
      const starsAlphas = [];
      const starsDirections = [];
      for (let i = 0; i < graphData.current.nodes.length*4; i++) { // Кількість зірок
        const x = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        const y = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        const z = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        starsVertices.push(x, y, z);

        starsAlphas.push(1.0); // Початкове значення альфа-каналу для кожної зірки

        starsDirections.push(new THREE.Vector3(
          (Math.random() - 0.5) * 0.01,
          (Math.random() - 0.5) * 0.01,
          (Math.random() - 0.5) * 0.01
        ));
      }

      starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starsVertices, 3));
      starGeometry.setAttribute('alpha', new THREE.Float32BufferAttribute(starsAlphas, 1));
      
      const stars = new THREE.Points(starGeometry, new THREE.ShaderMaterial({
        uniforms: {
          pointTexture: { value: blurredCircleTexture },
        },
        vertexShader: starsVert,
        fragmentShader: starsFrag,
        transparent: true,
        depthWrite: false,
      }));

      stars.userData.directions = starsDirections; // Зберігаємо напрямки руху як користувацькі дані
      scene.add(stars); // Додаємо зірки безпосередньо до сцени графу

      const animateStars = () => {
        const positions = stars.geometry.attributes.position.array;
        const directions = stars.userData.directions;

        for (let i = 0; i < positions.length; i += 3) {
          positions[i] += directions[i / 3].x     * 2; // Оновлення позицій зірок
          positions[i + 1] += directions[i / 3].y * 2;
          positions[i + 2] += directions[i / 3].z * 2;
          
          // Зміна напрямку руху для створення ефекту мерехтіння
          
          if (Math.random() > 0.995) {
            directions[i / 3].x *= -1;
          }
          if (Math.random() > 0.995) {
            directions[i / 3].y *= -1;
          }
          if (Math.random() > 0.995) {
            directions[i / 3].z *= -1;
          }
        }
        stars.geometry.attributes.position.needsUpdate = true; // Оновлюємо геометрію для рендерингу
        requestAnimationFrame(animateStars);
      }
      animateStars();
    }

    if (USE_AUDIO) {
      // Створення аудіо-контексту та аналізатора
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
      // Завантаження аудіо-треку
      const audio = new Audio('/track-0.mp3');
      const source = audioContext.createMediaElementSource(audio);

      audio.currentTime = 3600;

      // Створення аналізаторів
      const bassAnalyser = audioContext.createAnalyser();
      const bassDataArray = new Uint8Array(bassAnalyser.frequencyBinCount);
      const bass = audioContext.createBiquadFilter();
      bass.type = 'lowpass';
      bass.frequency.setValueAtTime(150, audioContext.currentTime);
      source.connect(bass);
      bass.connect(bassAnalyser);

      const midAnalyser = audioContext.createAnalyser();
      const midDataArray = new Uint8Array(midAnalyser.frequencyBinCount);
      const highCut = audioContext.createBiquadFilter();
      highCut.type = 'lowpass';
      highCut.frequency.setValueAtTime(2000, audioContext.currentTime);
      source.connect(highCut);
      const lowCut = audioContext.createBiquadFilter();
      lowCut.type = 'highpass';
      lowCut.frequency.setValueAtTime(600, audioContext.currentTime);
      highCut.connect(lowCut);
      lowCut.connect(midAnalyser);
      
      const highAnalyser = audioContext.createAnalyser();
      const highDataArray = new Uint8Array(highAnalyser.frequencyBinCount);
      const high = audioContext.createBiquadFilter();
      high.type = 'highpass';
      high.frequency.setValueAtTime(2000, audioContext.currentTime);
      source.connect(high);
      high.connect(highAnalyser);
      
      source.connect(audioContext.destination);
      // Очікування взаємодії користувача
      const userInteractionHandler = () => {
        audio.play().then(() => {
          document.removeEventListener('click', userInteractionHandler);
          document.removeEventListener('keydown', userInteractionHandler);
    
          // Запуск оновлення графа
          function updateGraph() {
            fgRef.current.d3Force('link').distance(link => {
              const node = graphData.current.nodes.find(node => node.id === link.source.id);
    
              // Реакція вузлів на вузькі частотні діапазони
              if (node.weight > 200) {
                bassAnalyser.getByteFrequencyData(bassDataArray);
                return bassDataArray.reduce((a, b) => a + b, 0) / bassDataArray.length * 100
                + (
                  Math.log((graphData.current.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.current.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else if (node.weight > 10) {
                midAnalyser.getByteFrequencyData(midDataArray);
                return midDataArray.reduce((a, b) => a + b, 0) / midDataArray.length * 5
                + (
                  Math.log((graphData.current.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.current.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else {
                highAnalyser.getByteFrequencyData(highDataArray);
                return highDataArray.reduce((a, b) => a + b, 0) / highDataArray.length 
                + (
                  Math.log((graphData.current.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.current.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              }
            });
    
            fgRef.current.d3ReheatSimulation();
            requestAnimationFrame(updateGraph);
          }
    
          updateGraph();
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      };

      document.addEventListener('click', userInteractionHandler);
      document.addEventListener('keydown', userInteractionHandler);
    }

    const onWindowResize = () => {

      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize( window.innerWidth, window.innerHeight );

    }

    const handleActivity = () => {lastActive = Date.now()};
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'touchmove'];
    events.forEach((event) => {window.addEventListener(event, handleActivity)});

    window.addEventListener( 'resize', onWindowResize );
    return () => {
      document.removeEventListener('click', handleMouseClick);
      document.removeEventListener('mousemove', handleMouseMove);
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  // dispatch(turnOnLoading());
  return (
    <ForceGraph3D
    graphData={graphData.current}
    ref={fgRef}
    // forceEngine="ngraph"
    // cooldownTicks={50}
    onEngineTick={() => {
      graphData.current.nodes.forEach((node, index) => {
        attributes.current.positions.set([node.x, node.y, node.z], index * 3);
      });

      tickCount += 1;  // Збільшуємо кількість тіків
      if (tickCount === 10 && firstTime.current && graphData.current.nodes.length > 2) {
        firstTime.current = false;
        
        // const zoomToFitDuration = 500;
        // fgRef.current.zoomToFit(zoomToFitDurations);
        // setTimeout(() => {
        //   fgRef.current.controls().update();
        // }, fitDuration+1);

        dispatch(turnOffLoading());
      }

      if (tickCount === 20) {
        if (graphData.current.nodes.length > 2 && focusNodeId.current) {
          focusOnNode.current(graphData.current.nodes.find(node => node.id === focusNodeId.current));
        }
        focusNodeId.current = null;
      }
      
    }}

    nodeColor={node => {
      return NODE_STATUS_COLORS[node.status].slice(0,7);
    }}
    
    onNodeDrag={() => needSimulation.current = true}
    onNodeDragEnd={node => {
      if (node.pinned){
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      } else {
        node.fx = null;
        node.fy = null;
        node.fz = null;
      }
    }}
    onEngineStop={() => {
      if (tickCount) {
        needSimulation.current = false;
        dispatch(updateCoordinatesRequest({
          nodes: graphData.current.nodes.map(node => ({
            id: node.id,
            x: node.x,
            y: node.y,
            z: node.z,
          }
          ))
        }))
      }
      tickCount = 0;
    }}
   
    linkDirectionalParticles={link => Math.log((graphData.current.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)*2}
   
    linkDirectionalParticleColor={link => {
      return link.type !== 0 ? '#40404033' : LINK_STATUS_COLORS[graphData.current.nodes.find(node => node.id === (link.source.id || link.source)).status];
    }}
    linkDirectionalParticleWidth={link => Math.log((graphData.current.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)/1.5}
    linkDirectionalParticleSpeed={0.0002}
    
    linkWidth={link => {
      return Math.log((graphData.current.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)/1.5;
    }}
   
    linkColor={link => link.type ? '#777777' : LINK_STATUS_COLORS[graphData.current.nodes.find(node => node.id === (link.source.id || link.source)).status]}
    onNodeClick={node => node.setIsFocused(true)}
    onNodeHover={node => {
      if (node) {
        if (!hoveredNode.current) {
          hoveredNode.current = node;
          node.importantView = true;
          getConnected(node.id, true).forEach(n => n.importantView = true);
        }
      }
      else {
        graphData.current.nodes.forEach(n => n.importantView = false);
        hoveredNode.current = null;
      }
    }}
    nodeOpacity={0.7}
    nodeRelSize={1}
    nodeResolution={32}
    nodeVal={node => node.weight*10 / 2}
    showNavInfo={false}
    nodeThreeObject={node => {
    //   let color = '#ffffff';
    //   let textLines = [];
    //   const TITLE_MAX_CHARS = 50; // Максимальна кількість символів
    //   const LINE_MAX_CHARS = 20; // Максимальна кількість символів в рядку
// 
    //   // Формуємо текст із перенесенням
    //   const title = node.title.length > TITLE_MAX_CHARS ? node.title.slice(0, TITLE_MAX_CHARS) + '...' : node.title;
    //   if (title) {
    //     const words = title.split(' ');
    //     let currentLine = '';
// 
    //     words.forEach(word => {
    //       const testLine = currentLine + (currentLine ? ' ' : '') + word;
    //       if (testLine.length > LINE_MAX_CHARS) {
    //         textLines.push(currentLine);
    //         currentLine = word;
    //       } else {
    //         currentLine = testLine;
    //       }
    //     });
// 
    //     if (currentLine) {
    //       textLines.push(currentLine);
    //     }
    //   } else {
    //     color = '#333333';
    //     textLines = ["Untitled"];
    //   }
// 
    //   const fontFace = 'Roboto, Arial';
    //   const fontWeight = 700;
    //   const textColor = 'white';
    //   const backgroundColor = '#000';
    //   const textHeight = 8;
    //   const padding = 5;
// 
    //   // Створюємо canvas для тексту
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');
// 
    //   const scale = Math.log(node.weight + 10)**2;
    //   // Налаштовуємо шрифт і розмір тексту
    //   context.font = `${fontWeight} ${textHeight * 64}px ${fontFace}`;
    //   const lineHeight = textHeight + padding*2; // Висота одного рядка
    //   const maxLineWidth = Math.max(...textLines.map(line => line.length))*textHeight*3;
// 
    //   // Визначаємо розміри canvas з урахуванням тексту і відступів
    //   canvas.width = maxLineWidth + 2 * padding;
    //   canvas.height = textLines.length * lineHeight + 2 * padding;
// 
    //   // Задаємо фон
    //   if (backgroundColor !== 'transparent') {
    //     context.fillStyle = backgroundColor;
    //     context.fillRect(0, 0, canvas.width, canvas.height);
    //   }
// 
    //   // Малюємо текст із вирівнюванням по лівому краю
    //   context.fillStyle = textColor;
    //   context.textAlign = 'left'; // Вирівнювання по лівому краю
    //   context.textBaseline = 'top'; // Вирівнювання по верхньому краю
    //   textLines.forEach((line, index) => {
    //     context.fillText(line, padding, padding + index * lineHeight);
    //   });
// 
    //   // Створюємо текстуру з canvas
    //   const texture = new THREE.CanvasTexture(canvas);
    //   texture.minFilter = THREE.LinearFilter;
    //   texture.wrapS = THREE.ClampToEdgeWrapping;
    //   texture.wrapT = THREE.ClampToEdgeWrapping;
// 
    //   // Створюємо матеріал і PlaneGeometry
    //   const material = new THREE.MeshBasicMaterial({ alphaTest: 0.1, map: texture, transparent: true });
    //   const geometry = new THREE.PlaneGeometry(canvas.width / canvas.height, 1); // Збереження пропорцій
    //   geometry.translate(0, 0, -corpusDepth / 2);
    //   geometry.scale(scale, scale, 1.);
// 
    //   // Створюємо Mesh
    //   const plane = new THREE.Mesh(geometry, material);
    //   return plane;

      
    }}
  />
  );

};

export default Graph3D;
