import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import graphReducer from './graphSlice'; // ваш graphSlice або graphReducer
import { authReducer } from './authSlice'; // ваш authSlice
import rootSaga from '../sagas'; // кореневий файл саг

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    graph: graphReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
