import React, { useRef, useEffect, useState, useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.css';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addNodeRequest, updateLinkRequest, deleteRequest, updateNodesRequest } from 'store/graphSlice';
import { logout } from 'store/authSlice';
import { useNavigate } from 'react-router-dom';
import store from '../../store';
import Graph3D from '../Graph3D';
import { NODE_PROPS_TO_UPDATE, LINK_PROPS_TO_UPDATE, STATUS_OPTIONS, TEXTURE_LOADER, } from 'utils/Constants'
import CardPage from './CardPage';


function getClosestPointOnLine(mouseX, mouseY, x1, y1, x2, y2, angle, offsetPercent = 0.15) {
    const A = mouseX - x1;
    const B = mouseY - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = lenSq !== 0 ? dot / lenSq : -1;

    // const shift = 0.3 * Math.cos(angle) * Math.sqrt(lenSq);
    // const shiftX = Math.cos(angle*Math.PI/180) * 0.1 * Math.sqrt(lenSq);
    // const shiftY = Math.sin(angle*Math.PI/180) * Math.sqrt(lenSq);

    const tMin = Math.max(offsetPercent, Math.sin(angle) / 4 + Math.cos(angle) / 2.9); // Позиція 10% від початку
    const tMax = 1 - Math.max(offsetPercent, - Math.sin(angle) / 4 - Math.cos(angle) / 2.9); // Позиція 90% від початку

    // Обмежуємо param між 10% та 90%
    const clampedParam = Math.max(tMin, Math.min(param, tMax));

    const closestX = Math.min(x1 + clampedParam * C, window.innerWidth - 40);
    const closestY = Math.min(y1 + clampedParam * D + 2, window.innerHeight - 40);

    return { x: closestX, y: closestY, dist: Math.sqrt((mouseX - closestX) ** 2 + (mouseY - closestY) ** 2) };
}

const NeuroSpace = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const graphData = useSelector(state => state.graph.graphData);
    const graphData = store.getState().graph.graphData;

    const neuroRef = useRef();
    const fgRef = useRef();
    const newConnectionLineRef = useRef();
    const lineButtonRef = useRef();
    const lineDeleteButtonRef = useRef();
    const lineTypeButtonRef = useRef();
    const lineAddNodeButtonRef = useRef();
    const lineReverseButtonRef = useRef();
    const stackRef = useRef(null);
    const stackButtonRef = useRef(null);
    const focusNodeId = useRef(null);
    const closestLink = useRef(null);
    const needSimulation = useRef(true);
    const focusOnNode = useRef(() => () => { console.log('Focus not defined'); });//move to graph3D
    const adjustUp = useRef(() => () => { console.log('adjustUp not defined'); });//move to graph3D
    const showAll = useRef(false);
    const showAllIcon = useRef();

    // const robotoTexture = useRef(TEXTURE_LOADER.load('roboto.png'));
    // const robotoData = useRef();
    
    const [stackExpanded, setStackExpanded] = useState(false);
    const [clonedGraphData, setClonedGraphData] = useState();

    const getConnected = useCallback((nodeId, nodes = null, source = null, type = null) => {
        if(!clonedGraphData) return;
        const links = clonedGraphData.links.filter(link => (
            (type === null || link.type === type) &&
            (
                (source === null && (link.target.id === nodeId || link.source.id === nodeId)) || // all directions
                (source === true && link.source.id === nodeId) || // node is source
                (source === false && link.target.id === nodeId) // node is target
            )
        ));
        if (nodes === false) {
            return links;
        } else if (nodes === true) {
            return links.map(link => {
                const connectedNode = link.target.id === nodeId
                    ? clonedGraphData.nodes.find(node => node.id === link.source.id)
                    : clonedGraphData.nodes.find(node => node.id === link.target.id);
                return connectedNode;
            }).filter(Boolean);
        } else {
            const linkElements = links;
            const nodeElements = links.map(link => {
                const connectedNode = link.target.id === nodeId
                    ? clonedGraphData.nodes.find(node => node.id === link.source.id)
                    : clonedGraphData.nodes.find(node => node.id === link.target.id);
                return connectedNode;
            }).filter(Boolean);

            return [...linkElements, ...nodeElements];
        }
    }, [clonedGraphData]);

    // Функція для клонування даних
    const cloneGraphData = (newData) => {
        const calculateNodeWeights = (nodeId, graphData, visited = new Set()) => {
            // Запобігаємо циклам
            if (visited.has(nodeId)) {
                return null;
            }
            visited.add(nodeId);
    
            // Знаходимо вузол
            const node = graphData.nodes.find(n => n.id === nodeId);
            if (!node) return null;

            // Початкова вага вузла (може бути надана або за замовчуванням 1)
            let primaryWeight = node.volume || 1;
            let secondaryWeight = 0;
            const statusWeights = Object.keys(STATUS_OPTIONS).reduce((acc, key) => {
                acc[key] = { primaryWeight: 0, secondaryWeight: 0, primaryCount: 0, secondaryCount: 0 }; // Ініціалізуємо значення для поточного вузла
                return acc;
            }, {});
            
            // Знаходимо дочірні вузли
            const childLinks = graphData.links.filter(link => link.target === nodeId);
            for (const link of childLinks) {
                const childData = calculateNodeWeights(link.source, graphData, visited);
                if (childData) {
                    if (link.type === 0) {
                        primaryWeight += childData.primaryWeight + childData.secondaryWeight;
                        statusWeights[childData.status].primaryWeight += childData.primaryWeight + childData.secondaryWeight;
                        statusWeights[childData.status].primaryCount += 1;
                    } else {
                        secondaryWeight += childData.primaryWeight + childData.secondaryWeight;
                        statusWeights[childData.status].secondaryWeight += childData.primaryWeight + childData.secondaryWeight;
                        statusWeights[childData.status].secondaryCount += 1;
                    }
                }
            }
    
            return {primaryWeight, secondaryWeight, statusWeights, status: node.status, childs: childLinks.length};
        };        

        return {
            nodes: newData.nodes.map(newNode => {
                // Знайдемо старий вузол, якщо він є, для збереження відсутніх властивостей
                const oldNode = clonedGraphData?.nodes?.find(oldNode => oldNode.id === newNode.id);
                const { primaryWeight, secondaryWeight, statusWeights, status, childs } = calculateNodeWeights(newNode.id, newData);
                // Зберігаємо старі пропси, яких немає в новому графі
                return {
                    ...newNode, // Оновлюємо нові дані
                    weight: primaryWeight + secondaryWeight,
                    primaryWeight: primaryWeight,
                    secondaryWeight: secondaryWeight,
                    statusWeights: statusWeights,
                    childs: childs,
                    x: oldNode?.x || newNode.x, // Залишаємо координати старого вузла
                    y: oldNode?.y || newNode.y, 
                    z: oldNode?.z || newNode.z,
                    vx: oldNode?.vx, // Залишаємо координати старого вузла
                    vy: oldNode?.vy, 
                    vz: oldNode?.vz,
                    __threeObj: oldNode?.__threeObj
                };
            }),

            links: newData.links.map(newLink => {
                // Знайдемо старий лінк, якщо він є
                // const oldLink = prevData?.links?.find(oldLink => oldLink.source === newLink.source && oldLink.target === newLink.target);
                
                // Зберігаємо старі пропси лінків
                return { ...newLink };
            })
        };
    };

    const blockAllTargets = (nodeId, changes = null, visited = new Set()) => {
        if (visited.has(nodeId)) return;
        visited.add(nodeId);

        const firstCall = changes === null;
        if (firstCall) changes = [];
        
        changes.push({ id: nodeId, status: 2 });

        const primaryTargets = getConnected(nodeId, true, true, 0); // nodeId, elements = true, nodes = null, source = null, type = null
        primaryTargets.forEach(target => {if (target.status !== 2) blockAllTargets(target.id, changes, visited)});

        if (firstCall) dispatch(updateNodesRequest(changes));
    }

    const stackExpand = () => {
        setStackExpanded(!stackExpanded);
    };

    const isGraphDataEqual = (nextData, prevData) => {
        const filterProps = (obj, props) => 
            props.reduce((filtered, prop) => {
                if (obj.hasOwnProperty(prop)) {
                    filtered[prop] = obj[prop];
                }
                return filtered;
            }, {});
    
        return isEqual(
            nextData?.nodes.map(node => filterProps(node, NODE_PROPS_TO_UPDATE)),
            prevData?.nodes.map(node => filterProps(node, NODE_PROPS_TO_UPDATE))
        ) && isEqual(
            nextData?.links.map(link => filterProps(link, LINK_PROPS_TO_UPDATE)),
            prevData?.links.map(link => filterProps(link, LINK_PROPS_TO_UPDATE))
        );
    };



    // const loadRobotoData = async () => {
    //     const response = await fetch('roboto_bold.json');
    //     const data = await response.json();
    //     robotoData.current = data;
    // };

    useEffect(() => {
        const fontAwesome = new FontFace(
            'FontAwesome',
            'url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/webfonts/fa-solid-900.woff2)'
        );
    
        const roboto = new FontFace(
            'Roboto',
            'url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf)' // URL для звичайного шрифту Roboto
        );
    
        // Завантажуємо шрифти паралельно
        Promise.all([fontAwesome.load(), roboto.load()])
            .then((loadedFonts) => {
                // Додаємо шрифти до документа
                loadedFonts.forEach(font => document.fonts.add(font));
                
                // Оновлюємо стан після завантаження
                setClonedGraphData(cloneGraphData(graphData));
            })
        // loadRobotoData().then(() => {
        //     setClonedGraphData(cloneGraphData(graphData));
        //     
        // });
    }, []);

    useEffect(() => {
        // Підписка на зміни в Redux store
        const unsubscribe = store.subscribe(() => { if (!isGraphDataEqual(graphData, clonedGraphData)) setClonedGraphData(cloneGraphData(graphData)); });

        // Очищаємо підписку, коли компонент буде демонтовано
        return () => {
            unsubscribe();
        };
    }, [clonedGraphData]);


    // useEffect(() => {
    //     setClonedGraphData(cloneGraphData(store.getState().graph.graphData));
    // }, []);

    // useEffect(() => {
    //     console.log(isGraphDataEqual(clonedGraphData, store.getState().graph.graphData));
    //     if (!clonedGraphData || !isGraphDataEqual(clonedGraphData, store.getState().graph.graphData)) {
    //         setClonedGraphData(cloneGraphData(store.getState().graph.graphData));
    //     }
    // }, [store.getState().graph.graphData]);

    const addNewNode = () => {
        const newNode = { id: uuidv4() };
        focusNodeId.current = newNode.id;
        dispatch(addNodeRequest(newNode));
        needSimulation.current = true;
    };


    // const handleOnMouseMove = (e) => {
    //     const mouseX = e.clientX;
    //     const mouseY = e.clientY;
    //     const pinned = !!lineButtonRef.current.dataset.x;
    //     let closestPoint;
    //     let minDist = Infinity;
    //     let angle = parseFloat(closestLine.current?.dataset.angle);
// 
    //     if (pinned) closestPoint = {
    //         x: lineButtonRef.current.dataset.x,
    //         y: lineButtonRef.current.dataset.y,
    //         dist: Math.sqrt((mouseX - lineButtonRef.current.dataset.x) ** 2 + (mouseY - lineButtonRef.current.dataset.y) ** 2)
    //     }
    //     else document.querySelectorAll('.link-line').forEach((line) => {
    //         const x1 = parseFloat(line?.dataset.x1);
    //         const y1 = parseFloat(line?.dataset.y1);
    //         const x2 = parseFloat(line?.dataset.x2);
    //         const y2 = parseFloat(line?.dataset.y2);
    //         const lAngle = parseFloat(line?.dataset.angle);
// 
    //         const point = getClosestPointOnLine(mouseX, mouseY, x1, y1, x2, y2, lAngle);
// 
    //         if (point.dist < minDist) {
    //             minDist = point.dist;
    //             closestLine.current = line;  // Оновлюємо найближчу лінію
    //             closestPoint = point;
    //             angle = lAngle;
    //         }
    //     });
// 
    //     // console.log(closestPoint);
    //     if (closestLine.current && closestPoint) {
    //         if (!pinned) {
    //             lineButtonRef.current.style.opacity = closestPoint.dist < 70 ? `${closestPoint?.dist > 35 ? 35 / closestPoint.dist * 0.99 : 0.99}` : '0';
    //             lineButtonRef.current.style.top = `${closestPoint.y}px`;
    //             lineButtonRef.current.style.left = `${closestPoint.x}px`;
    //             lineButtonRef.current.id = `line-button-${closestLine.current.id}`;
    //             lineButtonRef.current.style.backgroundColor = closestLine.current.style.borderTopColor;
    //             
    //             const size = Math.max(parseFloat(closestLine.current.style.width)/18, 20);
    //             lineButtonRef.current.style.fontSize = `${size * 0.6}px`;
    //             lineButtonRef.current.style.height = `${size}px`;
    //             lineButtonRef.current.style.width = `${size}px`;
    //             lineButtonRef.current.style.zIndex = parseFloat(closestLine.current.style.zIndex) + 1;
    //         }
// 
    //         if (closestPoint.dist >= 100) {
    //             closestLine.current = null;
    //             lineButtonRef.current.style.opacity = 0;
    //             lineDeleteButtonRef.current.classList.remove('bounce-in');
    //             lineTypeButtonRef.current.classList.remove('bounce-in');
    //             lineAddNodeButtonRef.current.classList.remove('bounce-in');
    //             lineReverseButtonRef.current.classList.remove('bounce-in');
    //             setTimeout(() => {
    //                 lineButtonRef.current.dataset.x = '';
    //                 lineButtonRef.current.dataset.y = '';
    //             }, 300);
    //             
    //         } else if (!pinned) {
    //             const buttonSpacing = 130; // Відстань між кнопками
    //             const angleInDegrees = angle * (180 / Math.PI);
// 
    //             lineAddNodeButtonRef.current.style.top = `${-buttonSpacing * Math.abs(Math.cos(angle)) + buttonSpacing * Math.sin(angle)}%`;
    //             lineAddNodeButtonRef.current.style.left = `${(Math.cos(angle) < 0 ? -buttonSpacing : buttonSpacing) * Math.sin(angle) + buttonSpacing * Math.cos(angle)}%`;
    //             lineAddNodeButtonRef.current.style.transform = `rotate(${angleInDegrees}deg)`;
// 
    //             lineTypeButtonRef.current.style.top = `${-buttonSpacing*1.5 * Math.abs(Math.cos(angle))}%`;
    //             lineTypeButtonRef.current.style.left = `${(Math.cos(angle) < 0 ? -buttonSpacing : buttonSpacing)*1.5 * Math.sin(angle)}%`;
    //             lineTypeButtonRef.current.style.transform = `rotate(${angleInDegrees}deg)`;
// 
    //             lineReverseButtonRef.current.style.top = `${-buttonSpacing * Math.abs(Math.cos(angle)) - buttonSpacing * Math.sin(angle)}%`;
    //             lineReverseButtonRef.current.style.left = `${(Math.cos(angle) < 0 ? -buttonSpacing : buttonSpacing) * Math.sin(angle) - buttonSpacing * Math.cos(angle)}%`;
    //             lineReverseButtonRef.current.style.transform = `rotate(${angleInDegrees}deg)`;
// 
    //             lineDeleteButtonRef.current.style.bottom = `${-buttonSpacing*1.5 * Math.abs(Math.cos(angle))}%`;
    //             lineDeleteButtonRef.current.style.right = `${(Math.cos(angle) < 0 ? -buttonSpacing : buttonSpacing)*1.5 * Math.sin(angle)}%`;
    //             lineDeleteButtonRef.current.style.transform = `rotate(${angleInDegrees}deg)`;
    //         }
    //     }
    // };

    const splitCurrentLinkAddNode = () => {

    }

    const reverseCurrentLink = () => {

    }

    const deleteCurrentLink = () => {
        dispatch(deleteRequest({links: [closestLink.current.id]}))
        
        if (clonedGraphData.nodes.find(node => node.id === closestLink.current.target.id).status === 2) {
            const childNodes = getConnected(closestLink.current.target.id, true, false, 0);
            const isBlocked = childNodes.some(child => child.id !== closestLink.current.source.id && child.status !== 3);

            if (!isBlocked) dispatch(updateNodesRequest([{ id: closestLink.current.target.id, status: 0 }]));
        }

        needSimulation.current = true;
    }

    const changeCurrentLinkType = () => {
        const newType = parseInt(closestLink.current.type) ? 0 : 1;
        dispatch(updateLinkRequest({ id: closestLink.current.id, type: newType }))

        const targetNode = clonedGraphData.nodes.find(node => node.id === closestLink.current.target.id);
        if ((targetNode.status === 2 && newType === 1) || (targetNode.status !== 2 && newType === 0)) {
            let childNodes = getConnected(targetNode.id, true, false, 0);

            if (newType) childNodes = childNodes.filter(node => node.id !== closestLink.current.source.id);
            else childNodes.push(clonedGraphData.nodes.find(node => node.id === closestLink.current.source.id));

            const isBlocked = childNodes.some(child => child.status !== 3);
            dispatch(updateNodesRequest([{ id: targetNode.id, status: isBlocked ? 2 : 0 }]));
        }
    }
    
    return clonedGraphData && (
        <>
        <CardPage
            graphData={clonedGraphData}
            getConnected={getConnected}
            blockAllTargets={blockAllTargets}
        />
        <div className='neuro-space' id='neuro-space'
            ref={neuroRef}
            // onMouseMove={handleOnMouseMove}
        >
            {/* <div
                ref={lineButtonRef}
                className="absolute rounded-full text-white line-button"
                style={{
                    zIndex: 1,
                    transform: 'translate(-50%, -50%)',
                    opacity: '0',
                    transition: 'opacity 0.2s ease-out',
                    boxShadow: '0 0 5px 0px rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center', // Вирівнювання по вертикалі
                    justifyContent: 'center', // Вирівнювання по горизонталі
                    cursor: 'pointer',
                    //aspectRatio: '1',
                    //padding: '5px',
                }}
                // onClick={(e) => {
                //     if (!!lineButtonRef.current.dataset.x) {
                //         lineDeleteButtonRef.current.classList.remove('bounce-in');
                //         lineTypeButtonRef.current.classList.remove('bounce-in');
                //         lineAddNodeButtonRef.current.classList.remove('bounce-in');
                //         lineReverseButtonRef.current.classList.remove('bounce-in');
                //         lineButtonRef.current.dataset.x = '';
                //         lineButtonRef.current.dataset.y = '';
// 
                //     } else {
                //         lineDeleteButtonRef.current.classList.add('bounce-in');
                //         lineTypeButtonRef.current.classList.add('bounce-in');
                //         lineAddNodeButtonRef.current.classList.add('bounce-in');
                //         lineReverseButtonRef.current.classList.add('bounce-in');
                //         lineButtonRef.current.dataset.x = e.clientX;
                //         lineButtonRef.current.dataset.y = e.clientY;
                //     }
                // }}
            >
                <i className="fas fa-wrench"
                    style={{
                        opacity: '0.2',
                    }}
                /> */}
                <button
                    title="Delete link"
                    ref={lineDeleteButtonRef}
                    className="absolute rounded-full bg-black text-white line-delete-button"
                    onClick={() => deleteCurrentLink()}
                >
                    <i className="fas fa-scissors" style={{ color: '#ff2222' }}/>
                </button>
                <button
                    title="Reverse link"
                    ref={lineReverseButtonRef}
                    className="absolute rounded-full bg-black text-white line-reverse-button"
                    onClick={() => reverseCurrentLink()}
                >
                    <i className="fas fa-arrow-right-arrow-left" style={{ transform: 'rotate(-45deg)'}}/>
                </button>
                <button
                    title="Split link with new node"
                    ref={lineAddNodeButtonRef}
                    className="absolute rounded-full bg-black text-white line-addnode-button"
                    onClick={() => splitCurrentLinkAddNode()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" fill="none" viewBox="0 0 100 100">
                        <path stroke="#fff" strokeLinecap="round" strokeWidth="10" d="M35 50h30M50 35v30"/>
                        <path fill="#fff" d="M1.464 91.465a5 5 0 1 0 7.072 7.07l-7.072-7.07Zm7.072 7.07 15-15-7.072-7.07-15 15 7.072 7.07ZM98.535 8.536a5 5 0 0 0-7.07-7.072l7.07 7.072Zm-7.07-7.072-15 15 7.07 7.072 15-15-7.07-7.072Z"/>
                        <circle cx="50" cy="50" r="31" stroke="#fff" strokeWidth="8"/>
                    </svg>


                </button>
                <button
                    title="Change type"
                    ref={lineTypeButtonRef}
                    className="absolute rounded-full bg-black text-white line-type-button"
                    onClick={() => changeCurrentLinkType()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" fill="none" viewBox="0 0 100 100">
                        <path stroke="#fff" strokeLinecap="round" strokeWidth="10" d="M5 80 85 5M50 65l15-15m15-15 15-15M20 95l15-15"/>
                    </svg>
                </button>
            {/* </div> */}
            <button
                className="absolute rounded-full text-white add-node-button"
                onClick={() => addNewNode()}
            >
                <i className="fas fa-plus" 
                />
            </button>
            <button
                className="absolute rounded-full text-white see-all-button"
                onClick={() => {
                    showAll.current = !showAll.current;
                    showAllIcon.current.className = showAll.current ? 'fas fa-eye-slash' : 'fas fa-eye';
                }}
            >
                <i ref={showAllIcon} className={showAll.current ? 'fas fa-eye-slash' : 'fas fa-eye'} />
            </button>
            <button
                className="absolute rounded-full text-white fit-zoom-button"
                onClick={() => {
                    const zoomToFitDuration = 300;
                    fgRef.current.zoomToFit(zoomToFitDuration);
                    setTimeout(() => {
                        fgRef.current.controls().update();
                    }, zoomToFitDuration+1);
                }}
            >
                <i className="fas fa-crosshairs"
                />
            </button>
            <button
                className="absolute rounded-full text-white settings-button"
                onClick={() => console.log('Settings button clicked')}
            >
                <i className="fas fa-gear"
                />
            </button>
            <button
                className="absolute rounded-full text-white logout-button"
                onClick={() => {
                    console.log('logout button clicked');
                    dispatch(logout());
                    navigate('/');
                }}
            >
                <i className="fa-solid fa-right-from-bracket"></i>
            </button>
            <button
                className="absolute rounded-full text-white trash-button"
                onClick={() => console.log('Trash button clicked')}
            >
                <i className="fas fa-trash"
                />
            </button>
            <svg id="new-connection-container" width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0, zIndex: 999, pointerEvents: 'none' }}>
                <line
                    ref={newConnectionLineRef}
                    id="drag-line"
                    x1="-1"
                    y1="-1"
                    x2="-1"
                    y2="-1"
                    className="new-connection-line"
                />
            </svg>
            {clonedGraphData?.nodes?.length > 0 && (<>
                <button
                    ref={stackButtonRef}
                    className={`absolute rounded-full text-white stack-button ${stackExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={() => stackExpand()}
                >
                    <i className={`fas fa-tasks-alt`}
                    />
                </button>
                <div
                    ref={stackRef}
                    className={`absolute stack-container ${stackExpanded ? 'expanded' : 'collapsed'}`}
                >
                    {/* <div className={`cards-stack ${stackExpanded ? 'expanded' : 'collapsed'}`}>
                        <SimpleBar
                            className={`cards-stack-scrollable-container ${stackExpanded ? 'expanded' : 'collapsed'}`}
                            style={{ maxHeight: '98%', minHeight: '98%', paddingBottom: 60 }}>
                            {clonedGraphData.nodes.map((node) => (
                                <Provider key={node.id} store={store}>
                                    <NeonGradientCard
                                        node={node}
                                        focusOnNode={focusOnNode}
                                        inStack={true}
                                        getConnected={getConnected}
                                        blockAllTargets={blockAllTargets}
                                    />
                                </Provider>
                            ))}
                        </SimpleBar>
                    </div> */}
                    <button
                        ref={stackButtonRef}
                        className={`stack-close-btn ${stackExpanded ? 'expanded' : 'collapsed'}`}
                        onClick={() => { console.log('stack button clicked'); stackExpand();}}
                    >
                        <i className={`fas fa-caret-down`}
                        />
                    </button>
                </div>
                <Graph3D
                    data={clonedGraphData}
                    fgRef={fgRef}
                    newConnectionLineRef={newConnectionLineRef}
                    focusOnNode={focusOnNode}
                    adjustUp={adjustUp}
                    focusNodeId={focusNodeId}
                    getConnected={getConnected}
                    lineButtonRef={lineButtonRef}
                    needSimulation={needSimulation}
                    neuroRef={neuroRef}
                    blockAllTargets={blockAllTargets}
                    showAll={showAll}
                    lineDeleteButtonRef={lineDeleteButtonRef}
                    lineTypeButtonRef={lineTypeButtonRef}
                    lineAddNodeButtonRef={lineAddNodeButtonRef}
                    lineReverseButtonRef={lineReverseButtonRef}
                    closestLink={closestLink}
                    // robotoTexture={robotoTexture.current}
                    // robotoData={robotoData.current}
                />
            </>)}
        </div>
        </>
    );
});

export default NeuroSpace;
